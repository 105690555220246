import cloneDeep from "lodash/cloneDeep";
import {any, contains, Dictionary, equals, is, map, path, values} from "ramda";
import {applyAnswers, isInheritedInstance} from "../answers";
import {ElementType, GroupSubType, GroupType, QuestionType, Stage, TableColumnType} from "../enums";
import {MagicAnswerKeys} from "../enums/answers";
import {ActionType} from "../enums/trigger";
import {
  applyAll,
  filter as filterTree,
  findNearestNeighbor,
  isMultipleGroup,
  isQuestion,
  isTopic,
  processContainerVisibility,
  processTreeBottomUp,
  processTreeTopDown,
  processTreeTopDownWithParentMultipleGroup,
} from "../profile";
import {
  processHighRiskCounts,
  processLowRiskCounts,
  processUnansweredRequiredCounts,
  processUnansweredRequiredInternalUseCounts,
  processUnknownRiskCounts,
} from "../profile/progressCounts";
import {processVisibilitySummary} from "../profile/visibilitySummary";
import {
  AsyncExpressionEvaluator,
  Expression,
  ExpressionResult,
  FindStringInFile,
  KitAggregate,
  logicalAnd,
  Operator,
  PointerLookup,
  subKeys,
} from "../rules/expression";
import {coerceToArray, isUnanswered, JSONObject, JSONQuestion, JSONValue, setTarget} from "../utils";
import {INSTANCE_ORDER} from "../utils/constants";
import factory from "../utils/logging";

const logger = factory.getLogger("ComputeProps");

type ComputeProp = (data: JSONObject) => Promise<any>;
type ComputeAnswer = (data: JSONObject) => Promise<string | undefined>;
type ComputeDefault = (data: JSONObject) => Promise<{key: string; value: ExpressionResult}>;
type Restore = () => void;

export interface Question {
  key: string;
  type: ElementType;
  questionId?: string;
  default?: Expression;
  helpText?: Expression;
  helpTextMarkdown?: Expression;
  hintText?: Expression;
  hintTextMarkdown?: Expression;
  label?: Expression;
  campaignRequirementLabel?: Expression;
  labelMarkdown?: Expression;
  fileLabel?: Expression;
  required?: Expression;
  readOnly?: Expression;
  riskAssessment?: Expression;
  visibilityRule?: Expression;
  visible?: boolean;
  quickHideVisible?: boolean;
  calculation?: Expression;
  isCalculation?: boolean;
  highRiskCount?: number;
  lowRiskCount?: number;
  unknownRiskCount?: number;
  visibilitySummary?: string[];
  unansweredRequiredCount?: number;
  unansweredRequiredInternalUseCount?: number;
  requiredAtConnectionStage?: Stage;
  multiple?: boolean;
  suppressDefaultMessage?: boolean;
  tableTitle?: Expression;
  tableTitleMarkdown?: Expression;
  noQuickHide?: boolean;
  filterVisible?: boolean;
  validationExpression?: Expression;
  validationError?: string;
  score?: Expression;
  editable?: boolean;
  requiredLanguages?: Expression | string[];
  requiredSubparts?: Expression | string[];
  requiredForSave?: Expression;
  showApprovalTable?: Expression;
  counterpartyCanEditAnswer?: boolean;
  sequence?: number;
  optionsAnswerKey?: string;
  externalValidation?: boolean;
  tags?: string[];
  minimumValue?: Expression;
  maximumValue?: Expression;
  restrictManualApproval?: Expression;
  hide?: boolean;
  prependText?: string;
  appendText?: string;
  subType?: GroupSubType;
  hiddenKeys?: string[];
  disallowAddWhen?: Expression;
  disallowDeleteWhen?: Expression;
  redirectAnswer?: Expression;
  myConnectionKey?: boolean;
  originalQuestionId?: string;
  aiPackage?: Expression;
  connectionOverviewKey?: boolean;

  columns?: JSONObject[];
  actions?: JSONObject[];
  children?: Question[];
}

export interface Column {
  key: string;
  type: TableColumnType;
  label?: Expression;
  labelMarkdown?: Expression;
  visibilityRule?: Expression;
  visible?: boolean;
  calculation?: Expression;
}

export interface Action {
  label?: Expression;
  enabled?: Expression;
}

export interface ActionData {
  data: JSONObject;
}

export interface ActionFull extends Action {
  type: ActionType;
  action: ActionData;
}

export type DecodeAnswerLabelLookup = (
  value: ExpressionResult,
  questionId: ExpressionResult,
  lookupColumn: ExpressionResult | undefined,
  question: Question,
) => Promise<ExpressionResult>;

export interface AsyncExpressionFunctions {
  decode?: DecodeAnswerLabelLookup;
  pointerLookup?: PointerLookup;
  kitAggregate?: KitAggregate;
  findStringInFile?: FindStringInFile;
}

export type UpdateCallback = <T>(
  target: object,
  key: string | number,
  value: T,
  targetQuestionInfo: TargetQuestionInfo,
) => void;

interface Prop {
  source?: keyof Question | keyof Column;
  target: keyof Question | "answer" | "column.label" | "column.labelMarkdown" | "optionsFilter" | "enabled";
  defaultValue?: boolean | number;
  getExpression?: (q: Question | Column | Action) => Expression | undefined;
}

interface MetaProp {
  target:
    | keyof Question
    | "containerVisibility"
    | "filterVisibility"
    | "groupReadOnly"
    | "inheritedVisibility"
    | "reviewInstanceReadOnly";
  compute: (
    q: JSONQuestion,
    a: JSONObject,
    updateCallback: UpdateCallback,
    init?: boolean,
    childrenMap?: Map<JSONQuestion, JSONQuestion[]>,
  ) => void;
}

interface WildcardDependency {
  match: (answerKey: string) => boolean;
  computeProps: ComputeProp[];
}

interface WildcardCalculation {
  match: (answerKey: string) => boolean;
  computeAnswers: ComputeAnswer[];
}

interface WildcardDefault {
  match: (answerKey: string) => boolean;
  computeDefaults: ComputeDefault[];
}

export interface TargetQuestionInfo {
  question: Question;
  property?: string;
  propertyIndex?: number;
}

export const COUNTERPARTY = "Counterparty";
export const isCounterpartyKey = (key: string) => key.startsWith(COUNTERPARTY + ".");
export const counterpartyBaseKey = (key: string) => key.substring(COUNTERPARTY.length + 1);

export const CONNECTION = "Connection";
export const isConnectionKey = (key: string) => key.startsWith(CONNECTION + ".");
export const connectionBaseKey = (key: string) => key.substring(CONNECTION.length + 1);

const setFilterVisibility = (q) => {
  if (q.filterVisible !== undefined) {
    q.visible = q.visible && (q.filterVisible || any((child: JSONQuestion) => !!child.visible, q.children || []));
  }
};
const processFilterVisibility = processTreeBottomUp(setFilterVisibility);
const processQuickHideVisible = (
  question: JSONQuestion,
  answers: JSONObject,
  updateCallback: UpdateCallback = (target, key, value) => {
    target[key] = value;
  },
  init?: boolean,
) => {
  if (init) {
    return;
  }
  const topics: JSONQuestion[] = filterTree((q) => q.type === ElementType.TOPIC, question);
  for (const topic of topics) {
    updateCallback(
      topic,
      "visible",
      topic.quickHideVisible === undefined ? !shouldQuickHide(topic, answers) : topic.quickHideVisible,
      {question: topic},
    );
    updateCallback(
      topic,
      "quickHideVisible",
      topic.quickHideVisible === undefined ? topic.visible : topic.quickHideVisible,
      {question: topic},
    );
    // topic.visible = topic.quickHideVisible === undefined ? !shouldQuickHide(topic, answers) : topic.quickHideVisible;
    // topic.quickHideVisible = topic.quickHideVisible === undefined ? topic.visible : topic.quickHideVisible;
  }
  for (const tab of question.children || []) {
    if (tab.type !== GroupType.TAB) {
      continue;
    }
    const someVisible = any((c) => !!c.visible, tab.children || []);
    if (!someVisible) {
      updateCallback(tab, "visible", false, {question: tab});
      // tab.visible = false;
    }
  }
};

const shouldQuickHide = (topic: Question, answers: JSONObject): boolean => {
  const dontHide = (question: Question): boolean => {
    // First check visibility - which was previously computed (probably)
    if (!question.visible) {
      return false;
    }
    // Check for answered
    if (
      answers[question.key] &&
      (!isMultipleGroup(question) ||
        (answers[question.key]![INSTANCE_ORDER] && answers[question.key]![INSTANCE_ORDER].length > 0))
    ) {
      return true;
    }
    // Check for required
    if (question.required || question.noQuickHide || (isTopic(question) && question.filterVisible)) {
      return true;
    }
    if (!isMultipleGroup(question) && question.children) {
      return any(dontHide, question.children);
    }
    return false;
  };
  return !(topic.requiredAtConnectionStage || dontHide(topic));
};

const forceChildrenReadOnly = processTreeTopDown((q: Question) => {
  q.readOnly = true;
});

const processReviewInstanceReadOnly = (question: JSONQuestion, answers: JSONObject) => {
  processTreeTopDownWithParentMultipleGroup((q, parentMultipleGroup) => {
    if (q.type !== ElementType.GROUP_INSTANCE || parentMultipleGroup?.subType !== GroupSubType.REVIEW) {
      return;
    }
    if (isInheritedInstance(path<JSONObject>(q.key.split("."), answers) ?? {})) {
      forceChildrenReadOnly(q);
    }
  }, question);
};

const processCascadingReadOnly = processTreeTopDown((q: Question) => {
  if (q.readOnly === true && q.type === ElementType.GROUP) {
    forceChildrenReadOnly(q);
  }
});

const processEditableFlag = processTreeBottomUp((q: Question) => {
  q.editable =
    ((isQuestion(q) || isMultipleGroup(q)) && !q.readOnly && q.visible) ||
    (q.children && any((c) => Boolean(c.editable), q.children));
});

function buildRequiredForSaveExp(q: Question | Column | Action) {
  return (q as Question).required && (q as Question).requiredForSave
    ? logicalAnd((q as Question).required, (q as Question).requiredForSave)
    : false;
}

export class ComputeProps {
  public get renormalize() {
    return this.results.renormalize;
  }
  public static async initialize(
    questionData: Question | JSONQuestion,
    propsWithSkips?: string[],
    shouldApplyQuickHide: boolean = false,
    locales?: string[],
    answerData?: JSONObject,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
    updateCallback?: UpdateCallback,
  ): Promise<ComputeProps> {
    const computeProps = new ComputeProps(
      questionData,
      propsWithSkips,
      shouldApplyQuickHide,
      locales,
      asyncExpressionFunctions,
      updateCallback,
    );
    await computeProps.reinitialize(answerData);
    return computeProps;
  }

  public static setGroupDependencies(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    groupKey: string,
    instanceId: string,
    question: Question,
  ): void {
    for (const prop of [...ComputeProps.allProps, {target: "optionsFilter"} as Prop]) {
      this.setPropGroupDependencies(answerKeyMap, groupKey, instanceId, prop, question);
    }

    for (const action of question.actions || []) {
      for (const prop of [...ComputeProps.allActionProps]) {
        this.setPropGroupDependencies(answerKeyMap, groupKey, instanceId, prop, action);
      }
    }

    this.setOptionsAnswerKey(answerKeyMap, question);
  }

  public static setExpressionGroupDependencies<T extends Expression | Array<Expression | Expression[]>>(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    groupKey: string,
    instanceId: string,
    expression: T,
    replaceInstanceId: boolean = true,
  ): T {
    if (
      typeof expression === "boolean" ||
      typeof expression === "number" ||
      typeof expression === "string" ||
      typeof expression === "undefined" ||
      expression === null
    ) {
      return expression;
    } else if (is(Array, expression)) {
      return map(
        (e) => this.setExpressionGroupDependencies(answerKeyMap, groupKey, instanceId, e, replaceInstanceId),
        expression,
      ) as unknown as T;
    }

    const operator = Object.keys(expression!)[0];
    if (operator === Operator.KEY) {
      const key = expression[operator];
      if (key === MagicAnswerKeys.INSTANCE_ID && replaceInstanceId) {
        return instanceId as T;
      }
      const instanceAndGroup = this.getInstanceAndGroup(answerKeyMap, expression[operator]);
      return {
        ...(expression as object),
        [operator]: instanceAndGroup
          ? [instanceAndGroup.groupKey, instanceAndGroup.instanceId, expression[operator]].join(".")
          : expression[operator],
      } as T;
    } else if (
      this.isGroupInstanceOperator(operator) &&
      typeof expression[operator][0] === "string" &&
      expression[operator][0] === groupKey
    ) {
      // Don't mess with the answer keys in the condition part of a GROUP_INSTANCE(S) expression on the same group we're in.
      return {
        ...(expression as object),
        [operator]: [
          this.setExpressionGroupDependencies(
            answerKeyMap,
            groupKey,
            instanceId,
            expression[operator][0],
            replaceInstanceId,
          ),
          expression[operator][1],
        ],
      } as unknown as T;
    } else if (operator === Operator.POINTER_LOOKUP) {
      // This is wild stuff. If the pointer is null we need the "target" expression with the group dependencies replaced, but if it isn't we need the original.
      return {
        [Operator.CONDITIONAL]: [
          {
            [Operator.EQUAL]: [
              this.setExpressionGroupDependencies(
                answerKeyMap,
                groupKey,
                instanceId,
                expression[operator][0],
                replaceInstanceId,
              ),
              null,
            ],
          },
          this.setExpressionGroupDependencies(
            answerKeyMap,
            groupKey,
            instanceId,
            expression[operator][1],
            replaceInstanceId,
          ),
          true,
          {
            [operator]: [
              this.setExpressionGroupDependencies(
                answerKeyMap,
                groupKey,
                instanceId,
                expression[operator][0],
                replaceInstanceId,
              ),
              expression[operator][1],
            ],
          },
          // We'll never get to this branch of the conditional, but we can use it to hack the dependencies
          this.setExpressionGroupDependencies(
            this.hackPointerLookupDependencies(answerKeyMap, groupKey),
            groupKey,
            instanceId,
            expression[operator][1],
            replaceInstanceId,
          ),
        ],
      } as unknown as T;
    } else {
      return {
        ...(expression as object),
        [operator]: this.setExpressionGroupDependencies(
          answerKeyMap,
          groupKey,
          instanceId,
          expression[operator],
          replaceInstanceId && !this.isGroupInstanceOperator(operator),
        ),
      } as T;
    }
  }

  private static allProps: Prop[] = [
    {target: "answer", source: "calculation"},
    {source: "visibilityRule", target: "visible", defaultValue: true},
    {target: "label"},
    {target: "labelMarkdown"},
    {target: "fileLabel"},
    {target: "hintText"},
    {target: "hintTextMarkdown"},
    {target: "helpText"},
    {target: "helpTextMarkdown"},
    {target: "tableTitle"},
    {target: "tableTitleMarkdown"},
    {target: "column.label"},
    {target: "column.labelMarkdown"},
    {target: "requiredForSave", getExpression: buildRequiredForSaveExp}, // Before required so it can use the expression on required
    {target: "required", defaultValue: false},
    {target: "default"},
    {target: "riskAssessment"},
    {target: "readOnly"},
    {source: "validationExpression", target: "validationError"},
    {target: "score"},
    {target: "requiredLanguages"},
    {target: "requiredSubparts"},
    {target: "showApprovalTable"},
    {target: "minimumValue"},
    {target: "maximumValue"},
    {target: "restrictManualApproval"},
    {target: "disallowAddWhen"},
    {target: "disallowDeleteWhen"},
    {target: "aiPackage"},
  ];

  private static allColumnProps: Prop[] = [
    {source: "visibilityRule", target: "visible", defaultValue: true},
    {target: "label"},
    {target: "labelMarkdown"},
    {target: "fileLabel"},
    {target: "answer", source: "calculation"},
  ];

  private static allActionProps: Prop[] = [{target: "label"}, {target: "enabled"}];

  private static metaProps: MetaProp[] = [
    {target: "unansweredRequiredCount", compute: processUnansweredRequiredCounts},
    {target: "unansweredRequiredInternalUseCount", compute: processUnansweredRequiredInternalUseCounts},
    {target: "highRiskCount", compute: processHighRiskCounts},
    {target: "lowRiskCount", compute: processLowRiskCounts},
    {target: "unknownRiskCount", compute: processUnknownRiskCounts},
    {target: "quickHideVisible", compute: processQuickHideVisible},
    {target: "visibilitySummary", compute: processVisibilitySummary},
    {target: "filterVisibility", compute: processFilterVisibility},
    {target: "containerVisibility", compute: processContainerVisibility},
    {target: "reviewInstanceReadOnly", compute: processReviewInstanceReadOnly},
    {target: "groupReadOnly", compute: processCascadingReadOnly},
    {target: "editable", compute: processEditableFlag},
  ];

  private static setPropGroupDependencies(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    groupKey: string,
    instanceId: string,
    {source, target}: Prop,
    question: Question | Action,
  ): void {
    if (!source) {
      source = target as keyof Question; // Never get here for target === "answer"
    }
    const expression: Expression | undefined = question[source] as Expression;
    if (expression) {
      if (typeof expression === "object") {
        // @ts-ignore TS 3.8
        question[source] = this.setExpressionGroupDependencies(answerKeyMap, groupKey, instanceId, expression);
      }
    }
  }

  private static isGroupInstanceOperator(operator: string) {
    return (
      operator === Operator.GROUP_INSTANCE ||
      operator === Operator.GROUP_INSTANCES ||
      operator === Operator.GROUP_INSTANCE_REVERSED
    );
  }

  private static getInstanceAndGroup(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    key: string,
  ): {groupKey: string; instanceId: string} | undefined {
    for (const k of subKeys(key)) {
      if (k in answerKeyMap) {
        return answerKeyMap[k];
      }
    }
    return undefined;
  }

  private static setOptionsAnswerKey(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    question: Question,
  ) {
    if (!question.optionsAnswerKey) {
      return;
    }

    const instanceAndGroup = this.getInstanceAndGroup(answerKeyMap, question.optionsAnswerKey);
    if (instanceAndGroup) {
      question.optionsAnswerKey = [
        instanceAndGroup.groupKey,
        instanceAndGroup.instanceId,
        question.optionsAnswerKey,
      ].join(".");
    }
  }

  private static hackPointerLookupDependencies(
    answerKeyMap: Dictionary<{groupKey: string; instanceId: string}>,
    groupKey: string,
  ): Dictionary<{groupKey: string; instanceId: string}> {
    const result = {...answerKeyMap};
    for (const key of Object.keys(result)) {
      if (result[key].groupKey === groupKey) {
        result[key] = {groupKey, instanceId: "*"};
      }
    }
    return result;
  }

  private results: {renormalize: boolean; originalAnswers: JSONObject | undefined} = {
    renormalize: true,
    originalAnswers: undefined,
  };
  private allProps: ComputeProp[] = [];
  private dependencies: Dictionary<ComputeProp[]> = {};
  private wildcardDependencies: Dictionary<WildcardDependency> = {};
  private metaProps: Array<MetaProp["compute"]> = [];
  private defaults: Dictionary<ComputeDefault[]> = {};
  private wildcardDefaults: Dictionary<WildcardDefault> = {};
  private calculations: Dictionary<ComputeAnswer[]> = {};
  private wildcardCalculations: Dictionary<WildcardCalculation> = {};
  private calculationDependencies: Dictionary<string[]> = {};
  private _restore: Restore[] = [];
  private childrenMap = new Map<JSONQuestion, JSONQuestion[]>();
  private newProps: Array<Promise<void>> = [];

  /**
   * Create ComputeProps instance, constructor will set up all the properties that will be calculated, etc. Nothing will
   * happen until you run the transform member method except defaulting into props where the target and source are different.
   *
   * propsWithSkips will run all possible property computations if no value is passed or set to undefined. Also, if you add
   * a "-" in front of a known prop name it will skip that property compute (e.g., "-unansweredRequiredCount")
   *
   * @param questionData The tree to compute
   * @param propsWithSkips Properties to compute (see above)
   * @param shouldApplyQuickHide
   * @param locales
   * @param asyncExpressionFunctions
   * @param updateCallback
   */
  private constructor(
    protected questionData: Question | JSONQuestion,
    protected propsWithSkips?: string[],
    private shouldApplyQuickHide: boolean = false,
    private locales?: string[],
    private readonly asyncExpressionFunctions?: AsyncExpressionFunctions,
    private updateCallback: UpdateCallback = (target, keyPath, value) => {
      target[keyPath] = value;
    },
  ) {
    Object.freeze(this);
  }

  public async transform(
    answerData: JSONObject,
    counterPartyAnswerData?: JSONObject,
    changedAnswerKey?: string,
  ): Promise<JSONObject> {
    this.results.renormalize = false;
    this.results.originalAnswers = answerData;
    const data = {...answerData, [COUNTERPARTY]: counterPartyAnswerData};
    if (changedAnswerKey) {
      await this.applyCalculations(data, [changedAnswerKey]);

      for (const dep of this.dependencies[changedAnswerKey] || []) {
        await dep(data);
      }

      for (const wildcardDep of values(this.wildcardDependencies)) {
        if (wildcardDep.match(changedAnswerKey)) {
          for (const computeProp of wildcardDep.computeProps) {
            await computeProp(data);
          }
        }
      }
    } else {
      for (const prop of this.allProps) {
        await prop(data);
      }
    }
    for (const metaProp of this.metaProps) {
      metaProp(this.questionData as JSONQuestion, data, this.updateCallback, false, this.childrenMap);
    }
    delete data[COUNTERPARTY];
    this.results.originalAnswers = undefined;
    return data;
  }

  public isDefaultDriver(answerKey: string): boolean {
    return answerKey in this.defaults || any((def) => def.match(answerKey), values(this.wildcardDefaults));
  }

  /**
   * Gets the defaults that should be applied  for a given answer change.
   *
   * @param answerData The preexisting answer data before any changes are applied.
   * @param counterpartyAnswerData The preexisting counterparty answer data.
   * @param changedAnswers The change that will be applied. E.g.: `{Entity_Country: "US"}`
   *
   * @returns the set of changes that must be applied to answerData, including the changedAnswers passed in.
   */
  public async getDefaults(
    answerData: JSONObject,
    counterpartyAnswerData: JSONObject,
    changedAnswers: JSONObject,
    validate?: (key: string, value: JSONValue, answers: JSONObject) => Promise<JSONValue>,
  ): Promise<JSONObject> {
    const data = applyAnswers(cloneDeep({...answerData, [COUNTERPARTY]: counterpartyAnswerData}), changedAnswers);
    const changes = {...changedAnswers};

    let keysToApply: string[] = Object.keys(changedAnswers);
    const skipTargets = new Set(keysToApply);
    await this.applyCalculations(data, keysToApply);

    const keysApplied: Set<string> = new Set();
    while (keysToApply.length > 0) {
      const nextKeys: Set<string> = new Set();
      for (const key of keysToApply) {
        for (const evaluate of this.defaults[key] || []) {
          await this.applyDefault(evaluate, data, changes, nextKeys, skipTargets, validate);
        }
        for (const wildcardDefault of values(this.wildcardDefaults)) {
          if (wildcardDefault.match(key)) {
            for (const computeDefault of wildcardDefault.computeDefaults) {
              await this.applyDefault(computeDefault, data, changes, nextKeys, skipTargets, validate);
            }
          }
        }
      }

      keysToApply.forEach((k) => keysApplied.add(k));
      keysToApply = [];
      for (const k of nextKeys) {
        if (keysApplied.has(k)) {
          logger.error(`Circular default dependency for ${k}`);
        } else {
          keysToApply.push(k);
        }
      }
    }
    return changes;
  }

  public restore() {
    applyAll(this._restore);
  }

  public reinitialize(): Promise<undefined>;
  public reinitialize(
    answerData?: JSONObject,
    counterPartyAnswerData?: JSONObject,
    changedAnswerKey?: string,
  ): Promise<JSONObject>;
  public async reinitialize(
    answerData?: JSONObject,
    counterPartyAnswerData?: JSONObject,
    changedAnswerKey?: string,
  ): Promise<JSONObject | undefined> {
    this.newProps.length = 0;

    const skipProps = this.propsWithSkips
      ? this.propsWithSkips?.filter((p) => p.substring(0, 1) === "-").map((p) => p.substring(1))
      : [];
    let props: string[] | undefined = this.propsWithSkips
      ? this.propsWithSkips?.filter((p) => p.substring(0, 1) !== "-")
      : undefined;
    props = Array.isArray(props) && props.length ? props : undefined; // There is value in being undefined

    let data: JSONObject | undefined;
    if (answerData) {
      this.results.renormalize = false;
      this.results.originalAnswers = answerData;
      data = {...answerData, [COUNTERPARTY]: counterPartyAnswerData};
    }

    if (!props || contains("answer", props)) {
      this.buildProps(this.questionData, new Set<string>(["answer"]), data);
    }
    const regularProps = new Set<string>(
      props ||
        [...ComputeProps.allProps, ...ComputeProps.allColumnProps, ...ComputeProps.allActionProps]
          .map((p) => p.target)
          .filter((p) => p !== "answer" && p !== "default" && skipProps.indexOf(p) === -1),
    );

    this.buildProps(this.questionData, regularProps, data);
    this.buildMetaProps(props, skipProps);
    this.buildDefaults(this.questionData, props);

    // IMPORTANT: DO NOT AWAIT BEFORE HERE
    // If Vue gets hold of the question tree before we're finished building props, it will
    // decorate the expressions and ExpressionEvaluator will declare them invalid

    this.buildReferenceChildrenMap(answerData);
    const renormalize = this.results.renormalize;

    await Promise.all(this.newProps);
    if (data) {
      delete data[COUNTERPARTY];
      this.results.originalAnswers = undefined;
      answerData = data;
    }

    if (answerData) {
      const transformedAnswers = await this.transform(answerData, counterPartyAnswerData, changedAnswerKey);
      this.results.renormalize = this.results.renormalize || renormalize;
      return transformedAnswers;
    }
    return undefined;
  }

  private async applyDefault(
    evaluate: ComputeDefault,
    data: JSONObject,
    changes: Dictionary<JSONValue>,
    nextKeys: Set<string>,
    skipTargetKeys: Set<string>,
    validate?: (key: string, value: JSONValue, answers: JSONObject) => Promise<JSONValue>,
  ) {
    let {key: targetKey, value} = await evaluate(data);
    if (validate) {
      value = await validate(targetKey, value, data);
    }
    if (skipTargetKeys.has(targetKey) || value === undefined) {
      return;
    }
    const splitKey = targetKey.split(".");
    const oldValue = path<ExpressionResult>(splitKey, data);
    if (!equals(oldValue, value) && (!isUnanswered(oldValue) || !isUnanswered(value))) {
      setTarget(splitKey, value, data);
      changes[targetKey] = value as JSONValue;
      nextKeys.add(targetKey);
    }
  }

  private buildProps(question: Question, props: Set<string>, data?: JSONObject) {
    for (const prop of ComputeProps.allProps) {
      if (!props.has(prop.target)) {
        continue;
      }
      this.buildProp(question, prop, data, {question});
    }
    if (question.children) {
      let children = question.children;
      if (isMultipleGroup(question)) {
        children = children.filter((c) => c.type === ElementType.GROUP_INSTANCE);
      }
      question.children.forEach((c) => this.buildProps(c, props, data));
    }
    if (question.columns) {
      question.columns.forEach((c, index) =>
        this.buildColumnProps(c as any as Column, props, data, {question, property: "columns", propertyIndex: index}),
      );
    }
    if (question.actions) {
      question.actions.forEach((a, index) =>
        this.buildActionProps(a as any as Action, props, data, {question, property: "columns", propertyIndex: index}),
      );
    }
  }

  private buildMetaProps(props?: string[], skipProps?: string[]) {
    if (this.metaProps.length === 0) {
      for (const prop of ComputeProps.metaProps) {
        if (
          (props &&
            !contains(prop.target, props) &&
            (prop.target !== "inheritedVisibility" || !contains("visible", props))) ||
          (prop.target === "quickHideVisible" && !this.shouldApplyQuickHide)
        ) {
          continue;
        }
        if (skipProps && contains(prop.target, skipProps)) {
          continue;
        }

        this.metaProps.push(prop.compute);
      }
    }

    // Run all to initialize values
    applyAll(this.metaProps, this.questionData, {}, this.updateCallback, true);
  }

  private buildProp(
    targetObject: Question | Column | Action,
    {source, target, defaultValue, getExpression}: Prop,
    data: JSONObject | undefined,
    targetQuestionInfo: TargetQuestionInfo,
    columnVisibilityFor?: string,
  ) {
    if (!source) {
      source = target as keyof Question; // Never get here for target === "answer". For 'column.' we'll improvise.
    }
    const sourcePath = source.split(".");
    let expression = getExpression ? getExpression(targetObject) : path(source.split("."), targetObject);
    if (expression !== undefined) {
      if (typeof expression === "object") {
        if (columnVisibilityFor) {
          expression = this.columnVisibilityExpression(columnVisibilityFor, expression);
        }

        if (target !== source) {
          this.smartSetTarget(target.split("."), defaultValue, targetObject, targetQuestionInfo);
          this.smartSetTarget(sourcePath, undefined, targetObject, targetQuestionInfo);
        }
        this._restore.push(() => this.smartSetTarget(sourcePath, expression, targetObject, targetQuestionInfo));
        const decode =
          this.asyncExpressionFunctions?.decode &&
          (async (...args: ExpressionResult) =>
            await this.asyncExpressionFunctions?.decode!(args[0], args[1], args[2], targetQuestionInfo.question));
        const evaluator = new AsyncExpressionEvaluator(expression as Expression, {
          locales: this.locales,
          ...this.asyncExpressionFunctions,
          decode,
        });
        const calculation = target === "answer";
        let computeProp: ComputeProp | ComputeAnswer;
        if (calculation) {
          const keySplit = (targetObject as Question).key.split(".");
          computeProp = async (answerData: JSONObject) => {
            const result = await evaluator.evaluate(answerData);
            const existing = path(keySplit, answerData);
            if (equals(result, existing)) {
              return undefined;
            }
            this.smartSetTarget(keySplit, result, answerData, targetQuestionInfo, this.results.originalAnswers!);
            return (targetObject as Question).key;
          };
          this.smartSetTarget(["isCalculation"], true, targetObject, {
            question: targetQuestionInfo.question,
          });
        } else {
          computeProp = async (answerData: JSONObject) => {
            const result = await evaluator.evaluate(answerData);
            if (
              isMultipleGroup(targetObject as Question) &&
              (target === "required" || target === "visible") &&
              (targetObject as Question)[target] !== result
            ) {
              this.results.renormalize = true;
            }
            this.smartSetTarget(target.split("."), result, targetObject, targetQuestionInfo);
          };
        }
        if (data) {
          this.newProps.push(computeProp(data));
        }
        this.allProps.push(computeProp);
        if (calculation) {
          // tslint:disable-next-line:prefer-const  use let rather than const to avoid weird Uglify JS const reassignment bug
          for (let answerKey of evaluator.answerKeys) {
            if (answerKeyHasWildcard(answerKey)) {
              if (!(answerKey in this.wildcardCalculations)) {
                const regex = answerKeyRegex(answerKey);
                this.wildcardCalculations[answerKey] = {
                  match: regex.test.bind(regex),
                  computeAnswers: [],
                };
              }
              this.wildcardCalculations[answerKey].computeAnswers.push(computeProp as ComputeAnswer);
            } else {
              if (!this.calculations[answerKey]) {
                this.calculations[answerKey] = [];
              }
              this.calculations[answerKey].push(computeProp as ComputeAnswer);
            }
          }
          this.calculationDependencies[(targetObject as Question).key] = evaluator.answerKeys;
        } else {
          const deps = new Set<string>(evaluator.answerKeys);
          // tslint:disable-next-line:prefer-const  use let rather than const to avoid weird Uglify JS const reassignment bug
          for (let answerKey of deps) {
            if (answerKeyHasWildcard(answerKey)) {
              if (!(answerKey in this.wildcardDependencies)) {
                const regex = answerKeyRegex(answerKey);
                this.wildcardDependencies[answerKey] = {
                  match: regex.test.bind(regex),
                  computeProps: [],
                };
              }
              this.wildcardDependencies[answerKey].computeProps.push(computeProp);
              // Get transitive dependencies from calculations
              for (const calcDepKey of Object.keys(this.calculationDependencies)) {
                if (this.wildcardDependencies[answerKey].match(calcDepKey)) {
                  for (const calculatedDependency of this.calculationDependencies[calcDepKey] || []) {
                    if (!deps.has(calculatedDependency)) {
                      deps.add(calculatedDependency);
                    }
                  }
                }
              }
            } else {
              if (!this.dependencies[answerKey]) {
                this.dependencies[answerKey] = [];
              }
              this.dependencies[answerKey].push(computeProp);
              // Get transitive dependencies from calculations
              for (const calculatedDependency of this.calculationDependencies[answerKey] || []) {
                if (!deps.has(calculatedDependency)) {
                  deps.add(calculatedDependency);
                }
              }
            }
          }
        }
      } else if (target !== source || getExpression) {
        this.smartSetTarget(target.split("."), expression, targetObject, targetQuestionInfo);
      }
    } else if (defaultValue !== undefined && targetObject[target] === undefined) {
      this.smartSetTarget(target.split("."), defaultValue, targetObject, targetQuestionInfo);
    }
  }

  private smartSetTarget(
    keyPath: string[],
    value: any,
    target: {},
    targetQuestionInfo: TargetQuestionInfo,
    originalAnswers?: {},
  ) {
    if (keyPath.length === 1) {
      this.updateCallback(target, keyPath[0], value, targetQuestionInfo);
    } else {
      if (!target[keyPath[0]]) {
        this.updateCallback(target, keyPath[0], {}, targetQuestionInfo);
      } else if (originalAnswers && target[keyPath[0]] === originalAnswers[keyPath[0]]) {
        target[keyPath[0]] = {...originalAnswers[keyPath[0]]};
      }
      this.smartSetTarget(
        keyPath.slice(1),
        value,
        target[keyPath[0]],
        targetQuestionInfo,
        originalAnswers?.[keyPath[0]],
      );
    }
  }

  private columnVisibilityExpression(groupKey: string, expression: Expression) {
    return {
      [Operator.OR]: [
        expression,
        {
          [Operator.NOT_EQUAL]: [
            {
              [Operator.GROUP_INSTANCE]: [groupKey, expression],
            },
            null,
          ],
        },
      ],
    };
  }

  private buildDefaults(question: Question, props?: string[]) {
    if (props && props.indexOf("default") < 0) {
      return;
    }

    if (question.default !== null && typeof question.default === "object") {
      const decode =
        this.asyncExpressionFunctions?.decode &&
        (async (...args: ExpressionResult) =>
          await this.asyncExpressionFunctions?.decode!(args[0], args[1], args[2], question));
      const evaluator = new AsyncExpressionEvaluator(question.default as Expression, {
        locales: this.locales,
        ...this.asyncExpressionFunctions,
        decode,
      });
      this._restore.push(() => this.smartSetTarget(["default"], evaluator.expression, question, {question}));
      this.smartSetTarget(["default"], undefined, question, {question});
      const computeDefault: ComputeDefault = async (data: JSONObject) => {
        const value = await evaluator.evaluate(data);
        return {key: question.key, value};
      };
      const deps = new Set<string>(evaluator.answerKeys);
      for (const answerKey of deps) {
        if (answerKeyHasWildcard(answerKey)) {
          if (!(answerKey in this.wildcardDefaults)) {
            const regex = answerKeyRegex(answerKey);
            this.wildcardDefaults[answerKey] = {
              match: regex.test.bind(regex),
              computeDefaults: [],
            };
          }
          this.wildcardDefaults[answerKey].computeDefaults.push(computeDefault);
          // Get transitive dependencies from calculations
          for (const calcDepKey of Object.keys(this.calculationDependencies)) {
            if (this.wildcardDefaults[answerKey].match(calcDepKey)) {
              for (const calculatedDependency of this.calculationDependencies[calcDepKey] || []) {
                if (!deps.has(calculatedDependency)) {
                  deps.add(calculatedDependency);
                }
              }
            }
          }
        } else {
          subKeys(answerKey).forEach((subKey) => {
            if (!this.defaults[subKey]) {
              this.defaults[subKey] = [];
            }
            this.defaults[subKey].push(computeDefault);
          });
          // Get transitive dependencies from calculations
          for (const calculatedDependency of this.calculationDependencies[answerKey] || []) {
            if (!deps.has(calculatedDependency)) {
              deps.add(calculatedDependency);
            }
          }
        }
      }
    }

    if (question.children) {
      let children = question.children;
      if (isMultipleGroup(question)) {
        children = children.filter((c) => c.type === ElementType.GROUP_INSTANCE);
      }
      question.children.forEach((c) => this.buildDefaults(c));
    }
  }

  private buildColumnProps(
    column: Column,
    props: Set<string>,
    data: JSONObject | undefined,
    targetQuestionInfo: TargetQuestionInfo,
  ) {
    const columnHeaderFor =
      targetQuestionInfo.question.type === GroupType.GROUP ? targetQuestionInfo.question.key : undefined;
    for (const prop of ComputeProps.allColumnProps) {
      if (!props.has(prop.target)) {
        continue;
      }
      this.buildProp(column, prop, data, targetQuestionInfo, prop.target === "visible" ? columnHeaderFor : undefined);
    }
  }

  private buildActionProps(
    action: Action,
    props: Set<string>,
    data: JSONObject | undefined,
    targetQuestionInfo: TargetQuestionInfo,
  ) {
    for (const prop of ComputeProps.allActionProps) {
      if (!props.has(prop.target)) {
        continue;
      }
      this.buildProp(action, prop, data, targetQuestionInfo);
    }
  }

  private async applyCalculations(data: JSONObject, keysToApply: string[]) {
    const keysApplied: Set<string> = new Set();
    while (keysToApply.length > 0) {
      const nextKeys: string[] = [];
      for (const key of keysToApply) {
        for (const evaluate of this.calculations[key] || []) {
          await this.applyCalculation(evaluate, data, nextKeys);
        }
        for (const wildcardCalculation of values(this.wildcardCalculations)) {
          if (wildcardCalculation.match(key)) {
            for (const computeAnswer of wildcardCalculation.computeAnswers) {
              await this.applyCalculation(computeAnswer, data, nextKeys);
            }
          }
        }
      }

      if (any((k) => keysApplied.has(k), nextKeys)) {
        throw new Error("Circular calculation dependency");
      }

      keysToApply.forEach((k) => keysApplied.add(k));
      keysToApply = nextKeys;
    }
  }

  private async applyCalculation(evaluate: ComputeAnswer, data: JSONObject, nextKeys: string[]) {
    const key = await evaluate(data);
    if (key === undefined) {
      return;
    }
    nextKeys.push(key);
  }

  private buildReferenceChildrenMap(answerData: JSONObject | undefined) {
    this.childrenMap.clear();
    processTreeTopDown((q: JSONQuestion) => {
      const children = [...(q.children || [])];
      if (
        (q.type === QuestionType.REFERENCE_TABLE ||
          q.type === QuestionType.REFERENCE_TABLE_GIG ||
          q.type === QuestionType.REFERENCE_TABLE_WITHOUT_SELECTION ||
          q.type === QuestionType.MASTER_DATA_PICKER) &&
        answerData
      ) {
        const key = q.optionsAnswerKey || "";
        const sourceTable = findNearestNeighbor((n) => n.key === key, q);
        if (sourceTable) {
          switch (q.type) {
            case QuestionType.REFERENCE_TABLE_WITHOUT_SELECTION:
              children.push(...(sourceTable.children || []));
              break;
            case QuestionType.REFERENCE_TABLE_GIG:
            case QuestionType.MASTER_DATA_PICKER:
            case QuestionType.REFERENCE_TABLE: {
              const value = coerceToArray(path(q.key.split("."), answerData) || []);
              for (const v of value) {
                const sourceRow = (sourceTable.children || []).find((r) => r.instance === v);
                if (sourceRow) {
                  children.push(sourceRow);
                }
              }
            }
          }
        }
      }

      if (children.length > 0) {
        this.childrenMap.set(q, children);
      }
    })(this.questionData as JSONQuestion);
  }
}

function answerKeyRegex(answerKey: string): RegExp {
  const r =
    "^" +
    answerKey
      .replace(/^\*\./, "\\w+\\.")
      .replace(/\.\*\./, "\\.\\w+\\.")
      .replace(/\.\*$/, "\\.\\w+") +
    "$";
  return new RegExp(r);
}

const WILDCARD = /(?:^|\.)\*(?:$|\.)/;

function answerKeyHasWildcard(answerKey: string): boolean {
  return WILDCARD.test(answerKey);
}
